import { Grid, MenuItem, TextField as MuiTextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { de } from "date-fns/locale";
import { FormikProps } from "formik";
import { MuiTelInput } from "mui-tel-input";
import { ChangeEvent } from "react";
import { UpdateUserRequestDTO, useLazySearchQuery } from "../redux/usersApi";
import PictureUploader from "./PictureUploader";
import { titleLabels } from "./labels";
import { withHidden } from "./withHidden";
import { createLetterPdf, LetterData } from "../hooks/createLetterPdf";
import {LoadingButton} from "./BulkEditPanel";

export interface UserDetailsFormProps {
  formik: FormikProps<Partial<UpdateUserRequestDTO>>;
  postalCodes: Record<number, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userData: any;
  isCardIssuer: boolean;
  searchDuplicates?: ReturnType<typeof useLazySearchQuery>[0];
  handleFieldChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}
const { createLetter } = createLetterPdf();

export const TextField = withHidden(MuiTextField);
export const TelInput = withHidden(MuiTelInput);

const UserDetailsForm = (props: UserDetailsFormProps) => {
  const handleBlur = () => {
    if (props.searchDuplicates) {
      props.searchDuplicates({
        firstname: props.formik.values.firstname ?? "",
        lastname: props.formik.values.lastname ?? "",
        dateOfBirth: props.formik.values.dateOfBirth ?? 0,
      });
    }
  };

  const handleAttatchFile = async (base64Image: string) => {
    props.formik.setFieldValue("image", base64Image, false);
    setTimeout(() => props.formik.setFieldTouched("image", true, true), 0);
  };

  const handleLetterPrint = async () => {
    const org = props.userData.orgName ?? '';
    const senderFirstname: string = props.userData.profile.firstname as string;
    const senderLastname = props.userData.profile.lastname as string;
    const translatedTitle = ({ mr: "Herr", ms: "Frau", divers: "" })[props.formik.values.title as string] || "";
    const city = props.postalCodes[props.formik.values.postalCode as number];

    const letters: LetterData[] = [{
      title: translatedTitle,
      firstname: props.formik.values.firstname ?? "",
      lastname: props.formik.values.lastname ?? "",
      address: props.formik.values.address ?? "",
      plz: props.formik.values.postalCode as number,
      city: city,
      senderFirstname: senderFirstname,
      senderLastname: senderLastname,
      municipality: org
    }];
    await createLetter(letters);
  };

  const formHelperTextProps = {
    sx: {
      "&.Mui-error": {
        marginLeft: 0,
        paddingLeft: 1,
        height: 0,
        textWrap: "nowrap",
      },
    },
  };

  return (
    <>
      <Grid item lg={5} xs={12} className="flex h-min-full flex-1 pt-6 pl-5">
        <PictureUploader
          handleAttatchFile={handleAttatchFile}
          picture={props.formik.values.image}
          rokkaHash={props.formik.values.rokkaHash ?? ''}
        />
      </Grid>
      <Grid container rowSpacing={3} columnSpacing={2} item xs={7}>
        <Grid item md={6} lg={12}>
          <TextField
            disabled={props.isCardIssuer}
            FormHelperTextProps={formHelperTextProps}
            error={props.formik.touched?.title && !!props.formik.errors.title}
            fullWidth
            helperText={
              props.formik.touched?.title && props.formik.errors.title
            }
            InputProps={{ disableUnderline: true }}
            label="Anrede"
            name="title"
            onChange={(e) => {
              props.handleFieldChange(e);
            }}
            required
            select
            SelectProps={{ native: false }}
            value={props.formik.values.title ?? ""}
            variant="filled"
          >
            {Object.entries(titleLabels).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            disabled={props.isCardIssuer}
            FormHelperTextProps={formHelperTextProps}
            error={
              !!props.formik.touched.lastname && !!props.formik.errors.lastname
            }
            fullWidth
            helperText={
              props.formik.touched.lastname && props.formik.errors.lastname
            }
            InputProps={{ disableUnderline: true }}
            label="Nachname"
            name="lastname"
            onBlur={handleBlur}
            onChange={(e) => {
              props.handleFieldChange(e);
              handleBlur();
            }}
            required
            value={props.formik.values.lastname ?? ""}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            disabled={props.isCardIssuer}
            FormHelperTextProps={formHelperTextProps}
            error={
              !!props.formik.touched.firstname &&
              !!props.formik.errors.firstname
            }
            fullWidth
            helperText={
              props.formik.touched.firstname && props.formik.errors.firstname
            }
            InputProps={{ disableUnderline: true }}
            label="Vorname"
            name="firstname"
            onBlur={handleBlur}
            onChange={(e) => {
              props.handleFieldChange(e);
              handleBlur();
            }}
            required
            value={props.formik.values.firstname ?? ""}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
            <DatePicker
              disabled={props.isCardIssuer}
              sx={{ width: "100%" }}
              disableFuture
              defaultValue={props.formik.values.dateOfBirth}
              label="Geburtsdatum"
              minDate={new Date("1900-01-01").getTime()}
              onChange={(date) => {
                props.handleFieldChange({
                  target: {
                    name: "dateOfBirth",
                    value: (date as unknown as Date).getTime(),
                  },
                } as unknown as ChangeEvent<HTMLInputElement>);
              }}
              onAccept={handleBlur}
              onClose={handleBlur}
              slotProps={{
                textField: {
                  required: true,
                  FormHelperTextProps: formHelperTextProps,
                  error:
                    props.formik.touched.dateOfBirth &&
                    !!props.formik.errors.dateOfBirth,
                  helperText:
                    props.formik.touched.dateOfBirth &&
                    props.formik.errors.dateOfBirth,
                  variant: "filled",
                  InputProps: { disableUnderline: true },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <TextField
          hidden={props.isCardIssuer}
          FormHelperTextProps={formHelperTextProps}
          error={
            !!props.formik.touched.address && !!props.formik.errors.address
          }
          fullWidth
          helperText={
            props.formik.touched.address && props.formik.errors.address
          }
          InputProps={{ disableUnderline: true }}
          label="Adresse"
          name="address"
          onChange={props.handleFieldChange}
          required
          type="text"
          value={props.formik.values.address ?? ""}
          variant="filled"
        />
        <LoadingButton
            hidden={props.userData.orgName !== "Flims"}
            onClick={handleLetterPrint}
            fullWidth
            size="large"
            variant="contained"
        >
          Begleitbrief drucken
        </LoadingButton>
      </Grid>

      <Grid item xs={3}>
        <TextField
          hidden={props.isCardIssuer}
          FormHelperTextProps={formHelperTextProps}
          error={
            !!props.formik.touched.postalCode &&
            !!props.formik.errors.postalCode
          }
          fullWidth
          helperText={
            props.formik.touched.postalCode && props.formik.errors.postalCode
          }
          InputProps={{ disableUnderline: true }}
          label="PLZ"
          name="postalCode"
          onChange={(e) => {
            props.handleFieldChange({
              target: {
                name: "postalCode",
                value: e.target.value,
              },
            } as ChangeEvent<HTMLInputElement>);
          }}
          required
          select
          SelectProps={{ native: false }}
          value={props.formik.values.postalCode ?? ""}
          variant="filled"
        >
          {Object.entries(props.postalCodes).map(([key]) => (
            <MenuItem key={key} value={parseInt(key, 10)}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          hidden={props.isCardIssuer}
          FormHelperTextProps={formHelperTextProps}
          fullWidth
          InputProps={{ disableUnderline: true }}
          label="Ort"
          name="city"
          disabled
          value={
            props.postalCodes[
              props.formik.values?.postalCode as keyof typeof props.postalCodes
            ] ?? ""
          }
          variant="filled"
        />
      </Grid>
      <Grid item xs={6}>
        <TelInput
          hidden={props.isCardIssuer}
          defaultCountry="CH"
          FormHelperTextProps={{
            sx: {
              "&.MuiFormHelperText-root": {
                marginLeft: 0,
                paddingLeft: 1,
                height: 0,
                textWrap: "nowrap",
                color: "red",
              },
            },
          }}
          fullWidth
          helperText={props.formik.touched.phone && props.formik.errors.phone}
          InputProps={{ disableUnderline: true }}
          label="Telefonnummer"
          name="phone"
          onChange={(value) => {
            props.handleFieldChange({
              target: { value, name: "phone" },
            } as ChangeEvent<HTMLInputElement>);
          }}
          required
          langOfCountryName="de"
          value={props.formik.values.phone ?? ""}
          variant="filled"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          hidden={props.isCardIssuer}
          FormHelperTextProps={formHelperTextProps}
          error={!!props.formik.touched.email && !!props.formik.errors.email}
          fullWidth
          helperText={props.formik.touched.email && props.formik.errors.email}
          InputProps={{ disableUnderline: true }}
          label="E-Mailadresse"
          name="email"
          onChange={props.handleFieldChange}
          required
          type="email"
          value={props.formik.values.email ?? ""}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          hidden={props.isCardIssuer}
          FormHelperTextProps={formHelperTextProps}
          fullWidth
          InputProps={{ disableUnderline: true }}
          label="Interner Kommentar"
          multiline
          name="comment"
          onChange={props.handleFieldChange}
          value={props.formik.values.comment ?? ""}
          variant="filled"
        />
      </Grid>
    </>
  );
};

export default UserDetailsForm;
