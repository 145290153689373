import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress as MuiCircularProgress,
  Typography,
} from "@mui/material";
import { FormikProps, useFormik } from "formik";
import { matchIsValidTel } from "mui-tel-input";
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  CreateUserRequestDTO,
  useCreateUserMutation,
  useGetMeQuery,
  useLazySearchQuery,
} from "../redux/usersApi";
import { Role } from "../typings/enums";
import CreateUserSuccessDialog from "./CreateUserSuccessDialog";
import UserDetailsForm from "./UserDetailsForm";
import { withHidden } from "./withHidden";
import { useNavigate } from "react-router-dom";
import {AdminForm} from "./AdminForm";

interface CreateUserDialogProps {
  handleClose: () => void;
  isLoading: boolean;
  open: boolean;
  orgId: string;
}

export default function CreateUserDialog(props: CreateUserDialogProps) {
  const navigate = useNavigate();
  const [search, { data: searchResults, isLoading: isSearchResultsLoading }] =
    useLazySearchQuery();
  const { currentData: userData, isError } = useGetMeQuery();
  const [createUser, { isLoading, data, isSuccess, status }] =
    useCreateUserMutation();

  const [createUserSuccessDialogOpen, setCreateUserSuccessDialogOpen] =
    useState(false);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
    if (isSuccess) {
      setCreateUserSuccessDialogOpen(true);
    }
  }, [data, data?.oneTimePassword, isError, isSuccess, navigate, status]);

  const orgs = new Set(Object.values(userData?.postalCodes || {}));
  const uniqueOrgArray = Array.from(orgs) as string[];

  const handleCloseDialog = () => {
    formik.resetForm();
    props.handleClose();
  };

  const handleSubmit = async () => {
    const userIsComplete = (
      _user: Partial<CreateUserRequestDTO["user"]>
    ): _user is CreateUserRequestDTO["user"] => {
      return formik.isValid;
    };

    if (!userIsComplete(formik.values)) {
      return;
    }

    try {
      await createUser({
        user: { ...formik.values, org: props.orgId, validUntil: null },
        orgId: props.orgId,
      });

      setCreateUserSuccessDialogOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const UserCreateSchema = Yup.object().shape({
    title: Yup.string().required("Bitte wählen Sie einen Titel aus"),
    firstname: Yup.string().required("Bitte geben Sie einen Vornamen ein"),
    lastname: Yup.string().required("Bitte geben Sie einen Nachnamen ein"),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Bitte geben Sie eine gültige E-Mail ein"
      )
      .required("Bitte geben Sie eine E-Mail ein"),
    phone: Yup.string()
      .test(
        "isValidTel",
        () => "Bitte geben Sie eine gültige Telefonnummer ein",
        (value) => !!value && matchIsValidTel(value, "CH")
      )
      .required("Bitte geben Sie eine Telefonnummer ein"),
    dateOfBirth: Yup.string()
      .typeError("Bitte geben Sie ein gültiges Datum ein")
      .required("Bitte geben Sie ein Geburtsdatum ein"),
    address: Yup.string().required("Bitte geben Sie eine Adresse ein"),
    rokkaHash: Yup.string().optional(),
    postalCode: Yup.number().required("Bitte geben Sie eine Postleitzahl ein"),
    comment: Yup.string().optional(),
    image: Yup.string().required("Bitte laden Sie ein Bild hoch"),
  });

  const formik: FormikProps<Partial<CreateUserRequestDTO["user"]>> = useFormik({
    initialValues: {},
    isInitialValid: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: UserCreateSchema,
    onSubmit: (_values) => void 0,
  });

  const handleFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.setFieldValue(e.target.name, e.target.value, false);
    setTimeout(() => formik.setFieldTouched(e.target.name, true, true), 0);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>
          <Grid container className="flex justify-between">
            <Grid item>Antrag erfassen</Grid>
            <DuplicateUserWarning
              resultsLength={searchResults?.length ?? 0}
              isSearchResultsLoading={isSearchResultsLoading}
            />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <UserDetailsForm
                isCardIssuer={userData?.role === Role.CardIssuer}
                formik={formik}
                userData={userData}
                postalCodes={userData?.postalCodes ?? {}}
                searchDuplicates={search}
                handleFieldChange={handleFieldChange}
              />

              <AdminForm
                  isSuperAdmin={userData?.role === Role.SuperAdmin}
                  orgs={uniqueOrgArray}
                  formik={formik}
                  handleFieldChange={handleFieldChange}
              />
            </Grid>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog}>Abbrechen</Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={!formik.isValid}
            onClick={handleSubmit}
          >
            Speichern
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <CreateUserSuccessDialog
        open={createUserSuccessDialogOpen}
        oneTimePassword={data?.oneTimePassword ?? "FEHLER"}
        handleClose={() => {
          handleCloseDialog();
          setCreateUserSuccessDialogOpen(false);
        }}
      />
    </div>
  );
}

const DuplicateUserWarning = (props: {
  isSearchResultsLoading: boolean;
  resultsLength: number;
}) => {
  if (props.isSearchResultsLoading) {
    return <CircularProgress size={30} />;
  } else if (props.resultsLength > 0) {
    return (
      <Typography className="p-1 px-2 bg-orange-300 rounded-md shadow-md">
        Ein Benutzer mit dem gleichen Namen und Geburtsdatum existiert bereits
      </Typography>
    );
  } else {
    return null;
  }
};

const CircularProgress = withHidden(MuiCircularProgress);
