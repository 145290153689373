import {Box, Button, TextField as MuiTextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormikProps, useFormik } from "formik";
import { omit } from "lodash";
import { matchIsValidTel } from "mui-tel-input";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  UpdateUserRequestDTO,
  UpdateUserResponseDTO,
  useGetMeQuery,
  useUpdateUserMutation,
} from "../redux/usersApi";
import { ApplicationStatus, PaymentStatus, Role } from "../typings/enums";
import { ActionPanel } from "./ActionPanel";
import { CardForm } from "./CardForm";
import SaveDialog from "./SaveDialog";
import UserDetailsForm from "./UserDetailsForm";
import { withHidden } from "./withHidden";
import {AdminForm} from "./AdminForm";

export const TextField = withHidden(MuiTextField);

interface DetailsPanelProps {
  selectedUserId: string;
  selectedUsers: string[];
  fieldValues: Omit<
    UpdateUserResponseDTO,
    "id" | "applicationStatus" | "paymentStatus" | "awaitingReply"
  >;
  applicationStatus: ApplicationStatus;
  paymentStatus: PaymentStatus;
  awaitingReply: boolean;
  validUntil: Date | null;
  dateCreated: string | null;
  rokkaHash: string | null;
  transactionDate: Date | null;
  transactionId: string | null;
}

export default function DetailsPanel(props: DetailsPanelProps) {
  const { currentData: userData, isError } = useGetMeQuery();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const isCardIssuer = userData?.role === Role.CardIssuer;

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  const UserCreateSchema = Yup.object().shape({
    title: Yup.string().required("Bitte wählen Sie einen Titel aus"),
    dateCreated: Yup.string().optional(),
    rokkaHash: Yup.string().optional(),
    firstname: Yup.string().required("Bitte geben Sie einen Vornamen ein"),
    lastname: Yup.string().required("Bitte geben Sie einen Nachnamen ein"),
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Bitte geben Sie eine gültige E-Mail ein"
      )
      .required("Bitte geben Sie eine E-Mail ein"),
    phone: Yup.string()
      .test(
        "isValidTel",
        () => "Bitte geben Sie eine gültige Telefonnummer ein",
        (value) => !!value && matchIsValidTel(value, "CH")
      )
      .required("Bitte geben Sie eine Telefonnummer ein"),
    dateOfBirth: Yup.number()
      .typeError("Bitte geben Sie ein gültiges Datum ein")
      .required("Bitte geben Sie ein Geburtsdatum ein"),
    address: Yup.string().required("Bitte geben Sie eine Adresse ein"),
    postalCode: Yup.number().required("Bitte geben Sie eine Postleitzahl ein"),
    comment: Yup.string().optional(),
    image: Yup.string().required("Bitte laden Sie ein Bild hoch"),
    cardId: Yup.string().matches(
      /^[A-Za-z0-9]{8}-[A-Za-z0-9]{3}-[A-Za-z0-9]{3}$/,
      "Bitte geben Sie eine gültige Karten-ID ein ########-###-###"
    ),
  });

  const formik: FormikProps<
      Partial<
          Omit<
              UpdateUserResponseDTO,
              "id" | "applicationStatus" | "paymentStatus" | "createdAt"
              >
          >
      > = useFormik({
    isInitialValid: false,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      ...omit(props.fieldValues, ["id", "applicationStatus", "paymentStatus"]),
      rokkaHash: props.fieldValues.rokkaHash ?? "",
      dateCreated: props.fieldValues.dateCreated ?? "",
    } as Partial<
        Omit<UpdateUserResponseDTO, "id" | "applicationStatus" | "paymentStatus" | "createdAt">
        >, // Cast initialValues to the expected type
    validationSchema: UserCreateSchema,
    onSubmit: (_values) => void 0,
  });

  const handleFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.setFieldValue(e.target.name, e.target.value, false);
    setTimeout(() => formik.setFieldTouched(e.target.name, true, true), 0);
  };

  const handleSaveUpdate = async (newValues: Partial<UpdateUserRequestDTO>) => {
    await updateUser({
      id: props.selectedUserId,
      data: { ...newValues, validUntil: null },
    });
    setSaveDialogOpen(false);
  };

  const orgs = new Set(Object.values(userData?.postalCodes || {}));
  const uniqueOrgArray = Array.from(orgs) as string[];

  return (
    <Box>
      <Grid
        className="flex h-min-full flex-1 pt-6 pl-5"
        container
        rowSpacing={3}
        columnSpacing={2}
      >

        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSaveDialogOpen(true);
          }}
        >

          <Grid container rowSpacing={3} columnSpacing={2}>
            <UserDetailsForm
              isCardIssuer={isCardIssuer}
              formik={formik}
              userData={userData}
              postalCodes={userData?.postalCodes ?? {}}
              handleFieldChange={handleFieldChange}
            />

            <CardForm
              isSuperAdmin={userData?.role === Role.SuperAdmin}
              isCardIssuer={userData?.role === Role.CardIssuer}
              formik={formik}
              handleFieldChange={handleFieldChange}
              validUntil={props.validUntil}
              transactionDate={props.transactionDate}
              transactionId={props.transactionId}
            />
            <AdminForm
                isSuperAdmin={userData?.role === Role.SuperAdmin}
                orgs={uniqueOrgArray}
                formik={formik}
                handleFieldChange={handleFieldChange}
            />

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Erstellungsdatum: {props.dateCreated}
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                type="submit"
                disabled={formik.dirty === false}
              >
                Änderungen speichern
              </Button>
            </Grid>
          </Grid>
        </form>

        <ActionPanel
          selectedUserId={props.selectedUserId}
          applicationStatus={props.applicationStatus}
          paymentStatus={props.paymentStatus}
          isCardIssuer={userData?.role === Role.CardIssuer}
          isSuperAdmin={userData?.role === Role.SuperAdmin}
          awaitingReply={props.awaitingReply}
          dateCreated={props.dateCreated}
          user={props.fieldValues}
        />
      </Grid>
      <SaveDialog
        oldValues={formik.initialValues}
        newValues={Object.keys(formik.touched).reduce(
          (acc: Partial<UpdateUserRequestDTO>, cur: string) => {
            const values = formik.values;
            const isKeyOfUser = (
              key: string | number | symbol
            ): key is keyof typeof values => key in values;

            if (!isKeyOfUser(cur)) {
              return acc;
            }

            return {
              ...acc,
              [cur]: values[cur],
            };
          },
          {}
        )}
        open={saveDialogOpen}
        handleClose={() => setSaveDialogOpen(false)}
        handleSave={handleSaveUpdate}
        isLoading={isLoading}
      />
    </Box>
  );
}
