import { jsPDF } from "jspdf";

export interface LetterData {
    title: string;
    firstname: string;
    lastname: string;
    address: string;
    plz: number;
    city: string;
    senderFirstname: string;
    senderLastname: string;
    municipality: string;
}

export const createLetterPdf = () => {

    const createLetter = async (letters: LetterData[]) =>
    {
        const doc = new jsPDF("p", "mm", "a4");
        let isFirstPage = true;

        for (const letter of letters) {
            const { title, firstname, lastname, address, plz, city, senderFirstname, senderLastname, municipality } = letter;

            if(!isFirstPage) {
                doc.addPage();
            }
            isFirstPage = false;

            const date = new Date();
            const day = date.getDate();
            const month = new Intl.DateTimeFormat('de-DE', { month: 'long' }).format(date);
            const year = date.getFullYear();
            const currentDate = `${day}. ${month} ${year}`;
            doc.setFont("Helvetica", "normal");
            doc.setFontSize(8);

            doc.setDrawColor(128,128,128);
            doc.setLineWidth(0.25);
            doc.line(160, 25, 186, 25);
            doc.setTextColor(128,128,128);
            doc.setFont("Helvetica", "bold");
            doc.text("Einwohnerkontrolle", 160, 28, { align: "left" });
            doc.setFont("Helvetica", "normal");
            doc.text("Via dil Casti 2", 160, 32, { align: "left" });
            doc.text("CH-7017 Flims Dorf", 160, 36, { align: "left" });
            doc.text("Tel. +41 81 928 29 29", 160, 40, { align: "left" });
            doc.text("www.gemeindeflims.ch", 160, 44, { align: "left" });

            doc.setTextColor(0);
            doc.setFontSize(11);

            doc.text(title, 25, 60);
            doc.text(firstname + ' ' + lastname, 25, 64);
            doc.text(address, 25, 68);
            doc.text(plz + ' ' + city, 25, 72);


            doc.setFontSize(6);
            doc.text(senderFirstname + ' ' + senderLastname, 160, 60, { align: "left" });
            doc.text("Tel. +41 81 928 29 29", 160, 63, { align: "left" });
            doc.text("Einwohnerdienste@gemeindeflims.ch", 160, 66, { align: "left" });

            doc.setFontSize(11);

            // Date
            doc.text(municipality + ', ' + currentDate, 25, 100);

            // Subject
            doc.setFontSize(13);
            doc.setFont("Helvetica", "bold");
            doc.text("Einheimisch-Ausweis", 25, 115);

            // Greeting
            doc.setFontSize(11);
            doc.setFont("Helvetica", "normal");
            const preTitle = ({ Herr: "geehrter", Frau: "geehrte" })[title as string] || "";

            doc.text("Sehr " + preTitle + ' ' + title + ' ' + lastname, 25, 130);

            // Body Content
            const bodyText = [
                "In der Beilage überlassen wir Ihnen den neuen Einheimisch-Ausweis.",
                "Er ist fünf Jahre gültig (s. rechter Kartenrand).",
                "",
                "Den Einheimisch-Ausweis können Sie u.a. für die verschiedensten Dienstleistungen einsetzen:",
                "- als Bestätigung des Einheimischstatus",
                "- Einheimischrabattierung in Geschäften (falls angeboten)",
                "- Bezug von vergünstigten Abonnements und Tickets bei den Bergbahnen",
                "- Mehrheitlich kostenloser öV Transport innerhalb der Destination Flims, Laax und Falera",
                "- Einheimischtarife bei öffentlichen Angeboten (Caumasee, Sportzentrum etc.)",
            ];
            doc.text(bodyText, 25, 140, { maxWidth: 190, align: "left", lineHeightFactor: 1.25 });
            const bodyTextBeforeLink = "Unter ";
            const linkText = "www.einheimisch-ausweis.ch";
            const bodyTextAfterLink = " finden Sie weitere Erläuterungen.";

            doc.text(bodyTextBeforeLink, 25, 187, { maxWidth: 190, align: "left" });

            const xLinkStart = doc.getTextWidth(bodyTextBeforeLink) + 25;
            const yPosition = 187;

            doc.setTextColor(0, 0, 255);
            doc.setFont("Helvetica", "normal");
            doc.text(linkText, doc.getTextWidth(bodyTextBeforeLink) + 25, 187);

            doc.setFont("Helvetica", "normal");
            doc.setTextColor(0, 0, 0);
            doc.text(bodyTextAfterLink, doc.getTextWidth(bodyTextBeforeLink) + doc.getTextWidth(linkText) + 25, 187, {
                maxWidth: 190,
                align: "left",
            });

            const linkWidth = doc.getTextWidth(linkText);
            doc.setDrawColor(0, 0, 255); // Set line color to blue
            doc.line(xLinkStart, yPosition + 1, xLinkStart + linkWidth, yPosition + 1);

            // Closing
            doc.text("Freundliche Grüsse", 25, 205);
            doc.text(senderFirstname + ' ' + senderLastname, 25, 235);
            doc.text("Einwohnerkontrolle", 25, 240);
         }

        // Save the document
        doc.save("ea-versand.pdf");
    };

    return {
        createLetter,
    };
};
