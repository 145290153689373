import { Close as CheckIcon, Check as CloseIcon } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Box as MuiBox,
  Button as MuiButton,
  ButtonGroup as MuiButtonGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import {
  GetOneUserResponseDTO,
  UpdateManyUsersRequestDTO,
  useGetManyUsersByIdsMutation,
  useGetMeQuery,
  useGetUsersQuery,
  useUpdateManyMutation,
} from "../redux/usersApi";
import { ApplicationStatus, CardStatus, Role } from "../typings/enums";
import BulkSaveDialog from "./BulkSaveDialog";
import CardStatusSelectComponent from "./CardStatusSelect";
import { withHidden } from "./withHidden";
import { useNavigate } from "react-router-dom";
import { useCreatePdf } from "../hooks/useCreatePdf";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { createLetterPdf, LetterData } from "../hooks/createLetterPdf";
const { createLetter } = createLetterPdf();

export interface BulkEditPanelProps {
  selectedUsers: string[];
  setSelectionModel: (selection: string[]) => void;
}

export default function BulkEditPanel(props: BulkEditPanelProps) {
  const [updateMany, { isLoading: isUpdateManyLoading }] =
    useUpdateManyMutation();
  const [getManyUsersById, { isLoading: isGetManyLoading }] =
    useGetManyUsersByIdsMutation();
  const { currentData } = useGetUsersQuery();
  const { currentData: authData, isError } = useGetMeQuery();
  const navigate = useNavigate();

  const { createCardPdf, isLoading: isPdfLoading } = useCreatePdf();

  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [targetApplicationStatus, setTargetApplicationStatus] =
    useState<ApplicationStatus>();

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  const areAllApplicationsPending = useMemo(() => {
    return props.selectedUsers
      .map((u) => currentData?.entities?.[u]?.applicationStatus)
      .every((status) => status === ApplicationStatus.Pending);
  }, [props.selectedUsers, currentData?.entities]);

  const commonApplicationStatus = useMemo(() => {
    return currentData?.entities[props.selectedUsers[0]]?.applicationStatus;
  }, [props.selectedUsers, currentData?.entities]);

  const commonCardStatus = useMemo(() => {
    return currentData?.entities[props.selectedUsers[0]]?.cardStatus;
  }, [props.selectedUsers, currentData?.entities]);

  const haveAllCardsSameStatus = useMemo(() => {
    return props.selectedUsers
      .map((u) => currentData?.entities?.[u]?.cardStatus)
      .every((status, _i, arr) => status === arr[0]);
  }, [props.selectedUsers, currentData?.entities]);

  const canPrint = useMemo(() => {
    return haveAllCardsSameStatus && commonCardStatus === CardStatus.NotPrinted;
  }, [haveAllCardsSameStatus, commonCardStatus]);

  const formik = useFormik<Partial<GetOneUserResponseDTO>>({
    initialValues: {
      cardStatus: currentData?.entities?.[props.selectedUsers[0]]?.cardStatus,
    },
    isInitialValid: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => void 0,
  });

  const isCardIssuer = authData?.role === Role.CardIssuer;

  const handleSaveCardStatus = () => {
    setIsSaveDialogOpen(true);
  };

  const handleRejectApplication = () => {
    setTargetApplicationStatus(ApplicationStatus.Rejected);
    setIsSaveDialogOpen(true);
  };

  const handleApproveApplication = () => {
    setTargetApplicationStatus(ApplicationStatus.Approved);
    setIsSaveDialogOpen(true);
  };

  const handleCardPrint = async () => {
    const users = await getManyUsersById(props.selectedUsers);

    // TS doesn't think `data` is around here, although it clearly is.
    if ("data" in users) {
      await createCardPdf(users.data);
    }
  };

  const handleLetterPrint = async () => {
    console.log(authData);
    if(!authData) return;
    const users = await getManyUsersById(props.selectedUsers);
    const org = authData.orgName ?? '';
    const senderFirstname: string = authData.profile.firstname as string;
    const senderLastname = authData.profile.lastname as string;

    const letters: LetterData[] = [];

    // TS doesn't think `data` is around here, although it clearly is.
    if ("data" in users) {
      for (const user of users.data) {
        console.log(user)
        const postalCodes = authData?.postalCodes as Record<string, string>;
        const city = postalCodes[user.postalCode.toString()] ?? org;

        const translatedTitle = ({ mr: "Herr", ms: "Frau", divers: "" })[user.title as string] || "";

        letters.push({
          title: translatedTitle ?? "",
          firstname: user.firstname ?? "",
          lastname: user.lastname ?? "",
          address: user.address ?? "",
          plz: user.postalCode as number,
          city: city ?? "",
          senderFirstname: senderFirstname,
          senderLastname: senderLastname,
          municipality: org,
        });
      }
    }

    await createLetter(letters);
  };


  const handleSubmit = async () => {
    const data = {} as UpdateManyUsersRequestDTO;
    if (targetApplicationStatus) {
      data["applicationStatus"] = targetApplicationStatus;
    }
    if (formik.values.cardStatus) {
      data["cardStatus"] = formik.values.cardStatus;
    }

    await updateMany({
      ...data,
      ids: props.selectedUsers,
    });
    props.setSelectionModel([]);
    setIsSaveDialogOpen(false);
  };

  return (
    <Box>
      <Grid item sm={12}>
        <Typography variant="h5">Anträge gleichtzeitig prozessieren</Typography>
        <Card className="my-5">
          <CardContent>
            Sie haben {props.selectedUsers.length} Anträge ausgewählt.
            {!isCardIssuer &&
              !haveAllCardsSameStatus &&
              !areAllApplicationsPending && (
                <Typography>
                  Es dürfen nur Anträge mit gleichenm Antragstatus oder
                  Kartenstatus gleichzeitig prozessiert werden.
                </Typography>
              )}
            {haveAllCardsSameStatus &&
              commonCardStatus === CardStatus.NotPrinted && (
                <Typography>
                  Es dürfen nur Benutzer mit zugewiesenen Card ID gleichzeitig
                  prozessiert werden.
                </Typography>
              )}
            {isCardIssuer && !haveAllCardsSameStatus && (
              <Typography>
                Es dürfen nur Anträge mit gleichem Kartenstatus prozessiert
                werden.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item lg={12} xs={12} className="flex h-min-full flex-1 pt-6 pl-5">
          <ButtonGroup hidden={!areAllApplicationsPending}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<CloseIcon htmlColor="white" />}
              onClick={handleApproveApplication}
            >
              Alle Genehmigen
            </Button>
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<CheckIcon htmlColor="white" />}
              onClick={handleRejectApplication}
            >
              Alle Ablehnen
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid item lg={6} xs={6} className="flex h-min-full flex-1 pt-6 pl-5">
        <CardStatusSelect
          hidden={!haveAllCardsSameStatus}
          formik={formik}
          isCardIssuer={isCardIssuer}
          isBulkEdit={true}
        />
      </Grid>
      <Grid item lg={6} xs={6} className="flex h-min-full flex-1 pt-6 pl-5">
        <Button
          onClick={handleSaveCardStatus}
          fullWidth
          size="large"
          variant="contained"
          hidden={!haveAllCardsSameStatus}
          disabled={commonCardStatus === formik.values.cardStatus}
        >
          Änderung speichern
        </Button>
      </Grid>
      <Grid item lg={6} xs={6} className="flex h-min-full flex-1 pt-6 pl-5">
        <LoadingButton
          onClick={handleCardPrint}
          fullWidth
          size="large"
          variant="contained"
          hidden={!isCardIssuer || !canPrint}
          loading={isPdfLoading || isGetManyLoading}
        >
          PDF für Kartendruck generieren
        </LoadingButton>
      </Grid>
      <Grid item lg={6} xs={6} className="flex h-min-full flex-1 pt-6 pl-5">
        <LoadingButton
            onClick={handleLetterPrint}
            fullWidth
            size="large"
            variant="contained"
            loading={isGetManyLoading}
            hidden={authData?.orgName !== "Flims"}
        >
          Begleitbrief drucken
        </LoadingButton>
      </Grid>
      <BulkSaveDialog
        oldValues={{
          applicationStatus: commonApplicationStatus,
          cardStatus: commonCardStatus,
        }}
        length={props.selectedUsers.length}
        newValues={{
          applicationStatus: targetApplicationStatus,
          cardStatus: formik.values.cardStatus,
        }}
        open={isSaveDialogOpen}
        handleClose={function (): void {
          setIsSaveDialogOpen(false);
        }}
        handleSave={handleSubmit}
        isLoading={isUpdateManyLoading}
      />
    </Box>
  );
}

export const Button = withHidden(MuiButton);
export const ButtonGroup = withHidden(MuiButtonGroup);
export const LoadingButton = withHidden(MuiLoadingButton);
export const CardStatusSelect = withHidden(CardStatusSelectComponent);
export const Box = withHidden(MuiBox);
